import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav';

function GOInterop() {
  return (
    <div className="GOInterop">
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/"><img src="resources/gointeroplogo.png" height="60" alt="" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="#sobre">Sobre</Nav.Link>
            <Nav.Link href="#certificacoes">Certificações</Nav.Link>            
            <Nav.Link href="#clientes">Clientes</Nav.Link>
            <Nav.Link href="#editoriais">Editoriais</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div >
  );
}

export default GOInterop;
