import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footnote() {
	return (
		<footer class="text-center text-lg-start bg-white">
			<section
				class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
			/>
			<section class="">
				<div class="container text-center text-md-start mt-5">
					<div class="row mt-3">
						<div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
							<h6 class="fw-bold mb-4">
								<i class="me-3"></i>GOInterop Tecnologia LTDA.
							</h6>							
							<p>
								36.995.311/0001-85
							</p>
							<p>Rua Irene Ramos Gomes de Mattos, 63.</p>
						</div>
						<div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
							<h6 class="fw-bold mb-4">
								<i class="me-3"></i>Fale conosco
							</h6>						
							<p>								
								<a href="mailto:contato@gointerop.com">contato@gointerop.com</a>
							</p>
							<p>
								<a href="callto:+5581981821227">+ 55 81 981821227</a>
							</p>
						</div>
					</div>
				</div>
			</section>
			<div class="text-center p-4">
				<p>Copyright © {(new Date().getFullYear())} GoInterop Tecnologia Ltda.</p>
				<p>Publicado em {new Date().toLocaleDateString("pt-BR")}</p>
			</div>
		</footer>);
}

export default Footnote;
