import React from 'react';

function Sobre(props) {
    return (
        <div id="sobre" className="Sobre">
            <div class="container">
                <h1 id="gointerop" class="display-4 lead">Quem somos</h1>                
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-xs-6 thumbnail"/>
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <img class="card-img-top" src="resources/gointeroplogo.png" alt="Logo"/>
                    </div>
                    <div class="col-lg-4 col-xs-6 thumbnail"/>
                </div>
                <div class="row">                    
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">                            
                            <div class="card-body">
                                <h5 class="card-title">Missão</h5>
                                <p class="card-text">A GOInterop é uma empresa que tem foco na interoperabilidade. Nosso objetivo é ajudar pessoas através da integração hospitalar, utilizando padrões, reutilizando tecnologias, respeitando a ética e mantendo a privacidade através da segurança.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">                            
                            <div class="card-body">
                                <h5 class="card-title">História</h5>
                                <p class="card-text">A GOInterop surgiu em 2020 a partir da ideia de dois amigos que trabalham com interoperabilidade na Saúde. Eles perceberam a necessidade que diferentes fornecedores tem, com as forças dos seus produtos, de trabalhar transparentes como uma única solução.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">                            
                            <div class="card-body">
                                <h5 class="card-title">Valores</h5>
                                <p class="card-text">Não fazemos integração, fazemos interoperabilidade: Técnica, Semântica e Operacional; Sempre agimos com ética; Sempre falamos com sinceridade; Consideramos segurança da informação no design; Sempre utilizamos padrões; Promovemos acesso aos dados a quem tem direito.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} export default Sobre;