import React from 'react';

function Certificacoes(props) {
    return (
        <div id="certificacoes" classnName="Certificacoes">
            <div class="container">
                <h1 class="display-4 lead">Nós somos certificados</h1>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">
                            <img class="card-img-top" src="resources/cptics.png" alt="Sobre" />
                            <div class="card-body">
                                <h5 class="card-title">Somos certificados SBIS</h5>
                                <p class="card-text">Nossos profissionais são profissionais certificados em Tecnologia da Informação e Comunicação em Saúde (cPTICS) pela Sociedade Brasileira de Informática em Saúde (SBIS). Temos, como obrigação, que respeitar o código de ética da SBIS em tudo que implementamos.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">
                            <img class="card-img-top" src="resources/hl7brasil.png" alt="Sobre" />
                            <div class="card-body">
                                <h5 class="card-title">A primeira editora FHIR do Brasil</h5>
                                <p class="card-text">Somos parceiros do HL7 Brasil e, por isso, participamos dos grupos de trabalho do HL7, além de ajudar na governança do perfil nacional. Por isso, nos tornamos a primeira editora FHIR do Brasil. Criamos arquitetura, perfis, conectores e serviços FHIR.</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-6 thumbnail">
                        <div class="" align="justify">
                            <img class="card-img-top" src="resources/rnds.png" alt="Sobre" />
                            <div class="card-body">
                                <h5 class="card-title">Vem para a RNDS!</h5>
                                <p class="card-text">Podemos habilitar seu sistema para a Rede Nacional de Dados em Saúde (RNDS). Criaremos seus perfis corporativos compatíveis com os perfis nacionais, além de conectores e serviços FHIR.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
} export default Certificacoes;