import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './Nav';
import Footnote from './Footnote';
import Clientes from './Clientes';
import Sobre from './Sobre';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import * as firebase from 'firebase';
import Certificacoes from './Certificacoes';

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Route path="/">
          <Sobre />
          <Certificacoes />          
          <Clientes />
        </Route>        
        <Footnote />
      </div>
    </Router>
  );
}

export default App;
