import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function Clientes() {
  return (
    <div id="clientes" className="Clientes">
      <div class="container">
        <h1 class="display-4 lead">Clientes</h1>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">
            <img class="card-img-top" src="resources/icone_ctc.png" alt="Connectcom" />           
            </div>
          </div>
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">              
            </div>
          </div>
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">
              <img class="card-img-top" src="resources/haoc.png" alt="Hospital Alemão Oswaldo Cruz" />              
            </div>
          </div>
        </div>
        <div class="row">          
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">      
            <img class="card-img-top" src="resources/bionexo.png" alt="Bionexo" />
            </div>
          </div>
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">              
            </div>
          </div>
          <div class="col-lg-4 col-xs-6 thumbnail">
            <div class="" align="justify">
              <img class="card-img-top" src="resources/sirio.png" alt="Sírio Libanês" />              
            </div>
          </div>
        </div>           
      </div>
    </div>
  );
}

export default Clientes;
